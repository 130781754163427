import React from 'react';
import { Button } from '../button';
import './bookshelfEnhanced.css'; // Updated to use enhanced styles

const GenreFilter = ({ genres, selectedGenres, onGenreToggle }) => (
  <div className="genre-filter">
    <div className="genre-buttons">
      {genres.map((genre) => (
        <Button
          key={genre.genre_id}
          variant={selectedGenres.includes(genre.name) ? "default" : "secondary"}
          onClick={() => onGenreToggle(genre.name)}
          className="genre-button"
        >
          {genre.name}
        </Button>
      ))}
    </div>
  </div>
);

export default GenreFilter;
