import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../button';
import { Input } from '../input';
import { updateBook } from '../apiService';
import './bookshelfEnhanced.css'; // Updated to use enhanced styles

const ConfirmDialog = ({ message, onConfirm, onCancel }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="confirm-dialog"
  >
    <p style={{ marginBottom: '20px', color: 'white' }}>{message}</p>
    <div className="confirm-actions">
      <Button variant="secondary" onClick={onCancel}>Cancel</Button>
      <Button variant="destructive" onClick={onConfirm}>Confirm</Button>
    </div>
  </motion.div>
);

const BookDetail = ({ book, onClose, onDelete, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedBook, setEditedBook] = useState(book);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleSave = async () => {
    try {
      await updateBook(book.book_id, editedBook);
      setIsEditing(false);
      onEdit(editedBook);
    } catch (error) {
      console.error('Failed to update book:', error);
    }
  };

  const handleClose = () => {
    if (isEditing) {
      setEditedBook(book);
      setIsEditing(false);
    } else {
      onClose();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className="book-detail-modal"
    >
      <div className="book-detail-header">
        {isEditing ? (
          <Input
            value={editedBook.title}
            onChange={(e) => setEditedBook({...editedBook, title: e.target.value})}
            placeholder="Title"
            className="edit-title-input"
          />
        ) : (
          <h2 className="book-title">{book.title}</h2>
        )}
      </div>

      <div className="book-detail-content">
        <div className="book-media">
          {book.cover_image_url ? (
            <img
              src={book.cover_image_url}
              alt={book.title}
              className="book-cover"
            />
          ) : (
            <div className="book-cover-placeholder">
              <span>{book.title[0]}</span>
            </div>
          )}
        </div>

        <div className="book-info">
          {isEditing ? (
            <>
              <Input
                value={editedBook.author}
                onChange={(e) => setEditedBook({...editedBook, author: e.target.value})}
                placeholder="Author"
                className="edit-input"
              />
              <Input
                value={editedBook.genres?.join(', ')}
                onChange={(e) => setEditedBook({...editedBook, genres: e.target.value.split(',').map(g => g.trim())})}
                placeholder="Genres (comma-separated)"
                className="edit-input"
              />
              <textarea
                value={editedBook.description || ''}
                onChange={(e) => setEditedBook({...editedBook, description: e.target.value})}
                placeholder="Description"
                className="edit-textarea"
              />
            </>
          ) : (
            <>
              <div className="info-section">
                <h3>Author</h3>
                <p>{book.author}</p>
              </div>
              
              <div className="info-section">
                <h3>Genres</h3>
                <p>{book.genres?.join(', ') || 'Unknown'}</p>
              </div>

              {book.description && (
                <div className="info-section">
                  <h3>Description</h3>
                  <p className="book-description">{book.description}</p>
                </div>
              )}

              {book.added_by_member && (
                <div className="info-section">
                  <h3>Added by</h3>
                  <p>{book.added_by_member}</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="modal-actions">
        <Button variant="secondary" onClick={handleClose}>
          {isEditing ? 'Cancel' : 'Close'}
        </Button>
        <div className="action-buttons">
          {isEditing ? (
            <Button variant="default" onClick={handleSave}>Save Changes</Button>
          ) : (
            <>
              <Button variant="destructive" onClick={() => setShowConfirmDelete(true)}>Delete</Button>
              <Button variant="default" onClick={() => setIsEditing(true)}>Edit</Button>
            </>
          )}
        </div>
      </div>

      <AnimatePresence>
        {showConfirmDelete && (
          <ConfirmDialog
            message="Are you sure you want to delete this book?"
            onConfirm={() => {
              setShowConfirmDelete(false);
              onDelete(book.book_id);
            }}
            onCancel={() => setShowConfirmDelete(false)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default BookDetail;
