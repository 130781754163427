import React from 'react';
import { motion } from 'framer-motion';

const EnhancedBook = ({ book, onClick, style }) => {
  // Constrain random values for more consistency
  const randomRotation = Math.random() * 1.4 - 0.7; // Less rotation (-0.7 to 0.7 degrees)
  const randomHeight = Math.random() * 14 - 7; // Less height variation (-7 to 7 pixels)

  return (
    <motion.div
      className="enhanced-book"
      onClick={() => onClick(book)}
      style={{
        ...style,
        '--random-height': `${200 + randomHeight}px`,
        '--random-rotation': `${randomRotation}deg`,
      }}
      whileHover={{
        scale: 1.1,
        rotateY: -20,
        z: 40,
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 15
        },
      }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="book-spine">
        <div className="book-spine-title">{book.title}</div>
        <div className="book-spine-author">{book.author}</div>
        <div className="book-spine-decoration top" />
        <div className="book-spine-decoration bottom" />
        <div className="book-spine-lines" />
      </div>
      <div className="book-cover">
        <div className="book-cover-pattern" />
        <div className="book-cover-shine" />
      </div>
      <div className="book-pages" />
      <div className="book-binding" />
    </motion.div>
  );
};

export default EnhancedBook;