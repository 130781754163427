import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MainPage from './MainPage';
import Navbar from './components/ui/Navbar';
import Bookshelf from './components/ui/bookshelf';
import QuizContainer from './components/ui/QuizContainer';
import RatingPage from './components/rating/RatingPage';

// Wrapper component to conditionally render Navbar
const NavbarWrapper = () => {
  const location = useLocation();
  const isVotePage = location.pathname === '/vote';
  
  return !isVotePage ? <Navbar /> : null;
};

export default function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Spooky Spooky Book Club</title>
        <meta 
          name="description" 
          content="Join Olympia's Spooky Spooky Book Club for chilling discussions on horror literature. Monthly meetings, diverse readings, and a welcoming community await. Dare to delve deeper?" 
        />
        <meta 
          name="keywords" 
          content="horror book club, Olympia book club, spooky book discussions, horror literature, join book club Olympia" 
        />
        <link rel="canonical" href="https://www.spookyspookybookclub.com" />
        {/* <script type="module">
          {`
            import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js";
            Chatbot.init({
              chatflowid: "5bc6bc00-6184-4154-8339-a70cf333b715",
              apiHost: "https://flowiseai-railway-production-36ff.up.railway.app",
            });
          `}
        </script> */}
      </Helmet>

      <Router>
        <NavbarWrapper />
        <Routes>
          <Route path="/" element={<MainPage showModal={false} />} />
          <Route path="/welcome" element={<MainPage showModal={true} />} />
          <Route path="/bookshelf" element={<Bookshelf />} />
          <Route path="/quiz" element={<QuizContainer />} />
          <Route path="/vote" element={<RatingPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}
