import React, { useState } from 'react';
import './rating.css';

const StarRating = ({ rating, onRatingChange }) => {
  const [hoverRating, setHoverRating] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    
    const container = e.currentTarget;
    const { left, width } = container.getBoundingClientRect();
    const x = e.clientX - left;
    const starWidth = width / 5;
    const starIndex = Math.floor(x / starWidth);
    const starPercent = (x % starWidth) / starWidth;
    
    let newRating = starIndex + 1;
    if (starPercent < 0.3) {
      newRating -= 0.5;
    } else if (starPercent < 0.7) {
      newRating = Math.floor(newRating);
    }
    
    setHoverRating(Math.max(0.5, Math.min(5, newRating)));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    handleMouseMove(e);
  };

  const handleMouseUp = () => {
    if (isDragging && hoverRating > 0) {
      onRatingChange(hoverRating);
    }
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    setHoverRating(0);
  };

  const handleStarClick = (index, e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const starPercent = x / rect.width;
    
    let newRating = index + 1;
    if (starPercent < 0.5) {
      newRating -= 0.5;
    }
    
    onRatingChange(newRating);
  };

  const displayRating = hoverRating || rating;

  return (
    <div 
      className="star-rating"
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={index}
          className="star-container"
          onClick={(e) => handleStarClick(index - 1, e)}
        >
          <svg
            className={`star ${
              displayRating >= index ? 'filled' :
              displayRating >= index - 0.5 ? 'half-filled' : ''
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <linearGradient id={`half-fill-${index}`}>
                <stop offset="50%" stopColor="#FFD700" />
                <stop offset="50%" stopColor="#e4e5e9" />
              </linearGradient>
            </defs>
            <path
              fill={displayRating >= index ? '#FFD700' : 
                    displayRating >= index - 0.5 ? `url(#half-fill-${index})` : '#e4e5e9'}
              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
          </svg>
        </div>
      ))}
      <span className="rating-value">
        {displayRating ? displayRating.toFixed(1) : '0.0'}
      </span>
    </div>
  );
};

export default StarRating;