const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://k67t787b4l.execute-api.us-west-1.amazonaws.com/Prod';

const fetchWithHeaders = async (url, options = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': '*',
  };

  const response = await fetch(url, {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({ error: 'Unknown error' }));
    throw new Error(errorData.error || 'Unknown error');
  }

  return response.json();
};

export const fetchCollectiveBooks = async () => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/collective-bookshelf`);
  } catch (error) {
    console.error('Error fetching collective books:', error);
    throw new Error(`Error fetching collective books: ${error.message}`);
  }
};

export const addBookToCollective = async (bookId, memberName) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/collective-bookshelf`, {
      method: 'POST',
      body: JSON.stringify({
        book_id: bookId,
        member_name: memberName,
      }),
    });
  } catch (error) {
    console.error('Error adding book to collective:', error);
    throw new Error(`Error adding book to collective: ${error.message}`);
  }
};

export const searchBooks = async (title) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/getBooks?title=${encodeURIComponent(title)}`);
  } catch (error) {
    console.error('Error searching books:', error);
    throw new Error(`Error searching books: ${error.message}`);
  }
};

export const updateBook = async (bookId, bookData) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/book/${bookId}`, {
      method: 'PUT',
      body: JSON.stringify(bookData),
    });
  } catch (error) {
    console.error('Error updating book:', error);
    throw new Error(`Error updating book: ${error.message}`);
  }
};

export const deleteBook = async (bookId) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/book/${bookId}`, {
      method: 'DELETE',
    });
  } catch (error) {
    console.error('Error deleting book:', error);
    throw new Error(`Error deleting book: ${error.message}`);
  }
};

export const fetchGenres = async () => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/genres`);
  } catch (error) {
    console.error('Error fetching genres:', error);
    throw new Error(`Error fetching genres: ${error.message}`);
  }
};

export const submitQuiz = async (quizResponses) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/quiz`, {
      method: 'POST',
      body: JSON.stringify({ quizResponses }),
    });
  } catch (error) {
    console.error('Error submitting quiz:', error);
    throw new Error(`Error submitting quiz: ${error.message}`);
  }
};

export const fetchBookRating = async () => {
  try {
    console.log(`Fetching current book rating from URL: ${API_BASE_URL}/vote`);
    const response = await fetchWithHeaders(`${API_BASE_URL}/vote`);
    
    console.log('Full API response:', response);
    
    // Validate response structure
    if (!response || (!response.book && !response.available_tags)) {
      console.warn('Unexpected response structure:', response);
      throw new Error('Invalid response from book rating endpoint');
    }
    
    return response;
  } catch (error) {
    console.error('Error fetching book rating:', {
      message: error.message,
      name: error.name,
      stack: error.stack
    });
    throw new Error(`Error fetching book rating: ${error.message}`);
  }
};

export const submitBookRating = async (ratingData) => {
  try {
    return await fetchWithHeaders(`${API_BASE_URL}/vote`, {
      method: 'POST',
      body: JSON.stringify(ratingData),
    });
  } catch (error) {
    console.error('Error submitting book rating:', error);
    throw new Error(`Error submitting book rating: ${error.message}`);
  }
};
