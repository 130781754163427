import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import StarRating from './StarRating';
import TagSelector from './TagSelector';
import { fetchBookRating, submitBookRating } from '../ui/apiService';
import './rating.css';

const RatingPage = () => {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableTags, setAvailableTags] = useState([]);
  const [formData, setFormData] = useState({
    rating: 0,
    comment: '',
    favorite_moment: '',
    would_recommend: false,
    tags: [],
    session_id: ''
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchBookDetails = async () => {
      try {
        const data = await fetchBookRating();
        setBook(data.book);
        setAvailableTags(data.available_tags);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBookDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submissionData = {
        ...formData,
        session_id: uuidv4()
      };
      await submitBookRating(submissionData);
      setSubmitted(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="rating-page loading">
        <div className="loading-spinner">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rating-page error">
        <h2>Book Rating Not Available</h2>
        <p>There is currently no book available for rating. Please check back later.</p>
        <button onClick={() => window.location.href = '/'} className="back-button">
          Return to Home
        </button>
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="rating-page success">
        <h2>Thank you for your rating!</h2>
        {book && (
          <div className="rating-stats">
            <p>Current average rating: {book.average_rating.toFixed(1)}</p>
            <p>Total ratings: {book.total_ratings}</p>
            {book.popular_tags && book.popular_tags.length > 0 && (
              <div className="popular-tags">
                <h3>Popular Tags:</h3>
                <div className="tag-cloud">
                  {book.popular_tags.map(tag => (
                    <span key={tag.tag_name} className="tag">
                      {tag.tag_name} ({tag.tag_count})
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="rating-page">
      {book && (
        <>
          <div className="book-header">
            <img 
              src={book.cover_image_url} 
              alt={book.title}
              className="book-cover"
            />
            <div className="book-info">
              <h1>{book.title}</h1>
              <h2>by {book.author}</h2>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="rating-form">
            <div className="form-group">
              <label>Your Rating</label>
              <StarRating
                rating={formData.rating}
                onRatingChange={(rating) => setFormData({
                  ...formData,
                  rating
                })}
              />
            </div>

            <div className="form-group">
              <label>Tags</label>
              <TagSelector
                availableTags={availableTags}
                selectedTags={formData.tags}
                onTagsChange={(tags) => setFormData({
                  ...formData,
                  tags
                })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="comment">Comments</label>
              <textarea
                id="comment"
                value={formData.comment}
                onChange={(e) => setFormData({
                  ...formData,
                  comment: e.target.value
                })}
                placeholder="What did you love or hate about the book?"
                rows={4}
              />
            </div>

            <div className="form-group">
              <label htmlFor="favorite_moment">Favorite Moment</label>
              <textarea
                id="favorite_moment"
                value={formData.favorite_moment}
                onChange={(e) => setFormData({
                  ...formData,
                  favorite_moment: e.target.value
                })}
                placeholder="What was your favorite moment or scene?"
                rows={3}
              />
            </div>

            <div className="form-group recommend-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={formData.would_recommend}
                  onChange={(e) => setFormData({
                    ...formData,
                    would_recommend: e.target.checked
                  })}
                />
                I would recommend this book to others
              </label>
            </div>

            <button type="submit" className="submit-button">
              Submit Rating
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default RatingPage;