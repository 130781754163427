import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../button';
import { Input } from '../input';
import BookDetail from './BookDetail';
import AddBookModal from './AddBookModal';
import GenreFilter from './GenreFilter';
import EnhancedBook from './EnhancedBook';
import BookFrame from './BookFrame';
import { FloatingOrb, FogEffect, SpiderWeb, DeadTree } from './SpookyEffects';
import { 
  fetchCollectiveBooks, 
  addBookToCollective, 
  deleteBook, 
  fetchGenres 
} from '../apiService';
import './bookshelfEnhanced.css';

const Shelf = ({ books, onBookClick, shelfNumber }) => {
  const spiderWebPositions = [
    { top: '-20px', left: '10px', rotation: 0 },
    { top: '-15px', right: '20px', rotation: 45 },
    { bottom: '-10px', left: '30%', rotation: 180 },
    { top: '50%', right: '40px', rotation: 90 },
  ];

  const fogPositions = [
    { bottom: '10px', left: '10%' },
    { bottom: '30px', right: '20%' },
    { top: '20px', left: '40%' },
  ];

  return (
    <div className="shelf-container">
      <div className="shelf">
        {spiderWebPositions.map((position, index) => (
          <SpiderWeb key={index} position={position} rotation={position.rotation} />
        ))}
        {fogPositions.map((position, index) => (
          <FogEffect key={index} position={position} />
        ))}
        {books.length === 0 ? (
          <div className="empty-shelf-space">
            <motion.div
              animate={{
                opacity: [0.1, 0.2, 0.1],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
            />
          </div>
        ) : (
          books.map((book, index) => (
            <motion.div
              key={book.book_id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <EnhancedBook 
                book={book} 
                onClick={() => onBookClick(book)}
                style={{
                  transform: `translateZ(${index * 5}px)`,
                }}
              />
            </motion.div>
          ))
        )}
      </div>
    </div>
  );
};

const InteractiveBookshelf = () => {
  const [selectedBook, setSelectedBook] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [books, setBooks] = useState([]);
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadBooks();
    loadGenres();
  }, []);

  const loadBooks = async () => {
    try {
      const data = await fetchCollectiveBooks();
      setBooks(data);
    } catch (err) {
      setError('Failed to load books');
    } finally {
      setLoading(false);
    }
  };

  const loadGenres = async () => {
    try {
      const data = await fetchGenres();
      setGenres(data);
    } catch (err) {
      console.error('Failed to load genres:', err);
    }
  };

  const handleAddBook = async (book, memberName) => {
    try {
      await addBookToCollective(book.book_id, memberName);
      await loadBooks();
    } catch (err) {
      setError('Failed to add book');
      throw err;
    }
  };

  const handleDeleteBook = async (bookId) => {
    try {
      await deleteBook(bookId);
      setSelectedBook(null);
      await loadBooks();
    } catch (err) {
      setError('Failed to delete book');
    }
  };

  const handleUpdateBook = async (updatedBook) => {
    try {
      setSelectedBook(updatedBook);
      await loadBooks();
    } catch (err) {
      setError('Failed to update book');
    }
  };

  const handleGenreToggle = (genre) => {
    setSelectedGenres((prev) =>
      prev.includes(genre)
        ? prev.filter((g) => g !== genre)
        : [...prev, genre]
    );
  };

  const filteredBooks = books.filter((book) => {
    const matchesSearch =
      book.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      book.author?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesGenres =
      selectedGenres.length === 0 ||
      selectedGenres.some((genre) => book.genres?.includes(genre));

    return matchesSearch && matchesGenres;
  });

  const booksPerShelf = 10;
  const getShelfBooks = (shelfNumber) => {
    const start = (shelfNumber - 1) * booksPerShelf;
    return filteredBooks.slice(start, start + booksPerShelf);
  };

  return (
    <motion.div
      className="bookshelf-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <BookFrame>
        <div className="bookshelf-header">
          <Button onClick={() => setShowAddModal(true)}>Add Book</Button>
        </div>

        <Input
          type="text"
          placeholder="Search books..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: '20px' }}
        />

        <GenreFilter
          genres={genres}
          selectedGenres={selectedGenres}
          onGenreToggle={handleGenreToggle}
        />

        {loading ? (
          <div className="loading-message">Loading books...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="shelves-container">
            <DeadTree side="left" />
            {[1, 2, 3].map((shelfNumber) => (
              <Shelf
                key={shelfNumber}
                books={getShelfBooks(shelfNumber)}
                onBookClick={setSelectedBook}
                shelfNumber={shelfNumber}
              />
            ))}
            <DeadTree side="right" />
            {[...Array(10)].map((_, i) => (
              <FloatingOrb 
                key={i} 
                delay={i * 0.5} 
                color={i % 2 === 0 ? '#333' : '#222'}
              />
            ))}
          </div>
        )}

        <AnimatePresence>
          {selectedBook && (
            <BookDetail
              book={selectedBook}
              onClose={() => setSelectedBook(null)}
              onDelete={handleDeleteBook}
              onEdit={handleUpdateBook}
            />
          )}
          {showAddModal && (
            <AddBookModal
              onClose={() => setShowAddModal(false)}
              onAdd={handleAddBook}
            />
          )}
        </AnimatePresence>
      </BookFrame>
    </motion.div>
  );
};

export default InteractiveBookshelf;