import React from 'react';
import { motion } from 'framer-motion';

const BookFrame = ({ children }) => {
  return (
    <div className="book-frame">
      <div className="frame-header">
        <div className="frame-ornament left">
          <svg viewBox="0 0 50 50" width="50" height="50">
            <circle cx="25" cy="25" r="20" className="ornament-circle" />
            <path d="M25,5 L25,45 M5,25 L45,25" className="ornament-cross" />
          </svg>
        </div>
        <motion.div
          className="frame-title"
          animate={{
            textShadow: [
              '0 0 4px rgba(255,215,0,0.3)',
              '0 0 8px rgba(255,215,0,0.6)',
              '0 0 4px rgba(255,215,0,0.3)',
            ],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: 'easeInOut',
          }}
        >
          ALDORE
        </motion.div>
        <div className="frame-ornament right">
          <svg viewBox="0 0 50 50" width="50" height="50">
            <circle cx="25" cy="25" r="20" className="ornament-circle" />
            <path d="M25,5 L25,45 M5,25 L45,25" className="ornament-cross" />
          </svg>
        </div>
      </div>
      <div className="frame-content">{children}</div>
      <div className="frame-footer">
        <div className="frame-support left" />
        <div className="frame-support right" />
      </div>
    </div>
  );
};

export default BookFrame;